<template>
  <div class="finance">
    <router-view />
  </div>
</template>

<script>

export default {
  name: 'finance',
  components: {
  }
}
</script>
<style lang="less" scoped>
.finance {
  width: 100%;
  height: 100%;
}
</style>
